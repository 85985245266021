<template>
    <div>
        <div>
            <b-button variant="primary" @click="$router.go(-1)" size="sm">
                <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                />
                <span class="align-middle">BACK</span>
            </b-button>
        </div>
        <div class="topics-details">
            <feather-icon 
                icon="HomeIcon"
                size="35"
            />
            <h1 class="mt-2">Marketing</h1> 
            <span>
                Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.
            </span>
        </div>
        <div class="d-flex mt-4">
            <h2>Banner Advertiesments </h2>
            <h4 class="ml-1 discount-text"> (Discount Prices)</h4>
        </div>
        <b-row>
            <b-col md="4">
                <marketing-add-details
                    topic='Rotational banner advertiesment'
                    banner_size='150×390px'
                    one_day_price='$80'
                    ten_day_price='$750'
                    one_month_price='$2000'
                    :is_banner_size="true"
                    :is_topic="true"
                    :is_banner_detail="false"
                 />
            </b-col>
            <b-col md="4">
                <marketing-add-details
                    topic='Top banner advertiesment'
                    banner_size='1022×115px'
                    banner_detail='This will be visible to every individual page'
                    one_day_price='$150'
                    ten_day_price='$1300'
                    one_month_price='$3250'
                    :is_banner_size="true"
                    :is_topic="true"
                    :is_banner_detail="true"
                 />
            </b-col>
            <b-col md="4">
                <marketing-add-details
                    topic='Pop up banner advertiesment'
                    banner_size='1022×115px'
                    one_day_price='$350'
                    ten_day_price='$3250'
                    one_month_price='$9500'
                    :is_banner_size="true"
                    :is_topic="true"
                    :is_banner_detail="false"
                 />
            </b-col>
        </b-row>

        <div class="d-flex mt-4">
            <h2>Top promotion listing </h2>
            <h4 class="ml-1 discount-text"> (Discounted Prices)</h4>
        </div>
        <span>
            Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.
        </span>

        <b-row>
            <div class="d-flex align-items-center justify-content-center mt-3 col-12">
                <b-col md="6">
                    <marketing-add-details
                        one_day_price='$70'
                        ten_day_price='$600'
                        one_month_price='$1500'
                        :is_top_promotion="true"
                    />
                </b-col>
            </div>
        </b-row>

        <div class="d-flex mt-4">
            <h2>Multimedia Content Creation</h2>
        </div>
        <span>
            Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.
        </span>

        <b-row>
            <div class="d-flex align-items-center justify-content-center mt-3 col-12">
                <b-col md="6">
                    <marketing-add-details
                        topic='Video Creation with all Graphics and Voicing'
                        one_day_price='$750'
                        ten_day_price='$1250'
                        one_month_price='$2250'
                        :is_topic="true"
                        :is_video_add="true"
                    />
                </b-col>
            </div>
        </b-row>

        <div class="d-flex mt-4">
            <h2>Email Marketing Campaign</h2>
            <h4 class="ml-1 discount-text"> (Discounted Prices)</h4>
        </div>
        <span>
            Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.
        </span>

        <b-row>
            <div class="d-flex align-items-center justify-content-center mt-3 col-12">
                <b-col md="7">
                    <marketing-add-details
                        topic='Video Creation with all Graphics and Voicing'
                        one_day_price='$750'
                        ten_day_price='$1250'
                        one_month_price='$2250'
                        :is_topic="true"
                        :is_email_add="true"
                    />
                </b-col>
            </div>
        </b-row>

    </div>
</template>

<script>
import {
  BRow,
  BCol,
//   BImg,
  BButton
} from "bootstrap-vue";
import MarketingAddDetails from './MarketingAddDetails.vue'

export default {
    components: {
        BRow,
        BCol,
        // BImg,
        BButton,
        MarketingAddDetails
    }
}
</script>
<style>
    .topics-details {
        text-align: center;
        padding: 1px 200px;
    }
    .discount-text {
        margin-top: 5px;
    }
    @media (max-width: 1024px) {
    .topics-details {
        padding: 0px !important;
    }
}
</style>