<template>
    <div>
        <b-card class="banner-detail-card mt-3">
            <div v-if="!is_email_add">
                <div class="banner-size mb-1" v-show="is_banner_size">
                    {{banner_size}}
                </div>
                <span class="banner-type" v-show="is_topic">{{topic}}</span>
                <div v-show="!is_top_promotion" class="mb-5">
                    <span v-if="is_banner_detail"> {{banner_detail}} </span>
                    <span v-else> <br /> </span>
                </div>
                <div v-if="!is_video_add">
                    <div class="d-flex justify-content-between marketing-price">
                        <span>1 Day Promotion</span>
                        <span>{{ one_day_price}}</span>
                    </div>
                    <div class="d-flex justify-content-between marketing-price">
                        <span>10 Day Promotion</span>
                        <span>{{ ten_day_price }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>1 Month Promotion</span>
                        <span>{{ one_month_price }}</span>
                    </div>
                </div>
                <div v-else>
                    <div class="d-flex justify-content-between marketing-price">
                        <span>2 Minutes Video</span>
                        <span>{{ one_day_price}}</span>
                    </div>
                    <div class="d-flex justify-content-between marketing-price">
                        <span>5 Minutes Video</span>
                        <span>{{ ten_day_price }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>10 Minutes Video</span>
                        <span>{{ one_month_price }}</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex mb-1">
                    <feather-icon 
                        icon="CheckCircleIcon"
                        size="15"
                        class="mr-50"
                    />
                    <span>Old family photos undoubtedly inspire a flood of memories.</span>
                </div>
                <div class="d-flex  mb-1">
                    <feather-icon 
                        icon="CheckCircleIcon"
                        size="15"
                        class="mr-50"
                    />
                    <span>Old family photos undoubtedly inspire a flood of memories.</span>
                </div>
                <div class="d-flex mb-1">
                    <feather-icon 
                        icon="CheckCircleIcon"
                        size="15"
                        class="mr-50"
                    />
                    <span>Old family photos undoubtedly inspire a flood of memories.</span>
                </div>
                <div class="d-flex">
                    <feather-icon 
                        icon="CheckCircleIcon"
                        size="15"
                        class="mr-50"
                    />
                    <span>Old family photos undoubtedly inspire a flood of memories.</span>
                </div>
                <div class="email-details mt-4 mb-2">
                    <h1 class="mt-2">$1000</h1> 
                    <span>
                        Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.
                    </span>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard
} from "bootstrap-vue";

export default {
    props: ['topic', 'banner_size', 'banner_detail', 'one_day_price', 'ten_day_price', 'one_month_price', 'is_banner_size', 'is_topic', 'is_banner_detail', 'is_top_promotion', 'is_video_add', 'is_email_add'],
    components: {
        BCard
    }
}
</script>

<style>
    .banner-detail-card {
        background: #000146 !important;
        padding: 50px 5px;
        border-radius: 1px !important;
    }
    .banner-size {
        background: white !important;
        border: white solid 1px;
        padding: 8px;
        width: 200px !important;
        border-radius: 5px;
        color: black;
        /* box-shadow: inset 10px 10px 0px 0px #444444; */
        box-shadow: inset 17px 0 5px -7px rgba(0,0,0,0.7) !important;
        
        text-align: center;
    }
    .banner-size-shadow {
        box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.7) !important;
    }
    .banner-type {
        font-size: 16px;
        font-weight: 500;
    }
    .marketing-price {
        border-bottom: 1px solid #ffffff85;
        margin-bottom: 10px;
    }
    .email-details {
        text-align: center;
    }
</style>